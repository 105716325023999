import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';

import { ClientEventType, ScribeMeetingType, SpinachAPIPath, SummaryJson } from '@spinach-shared/types';

import { postSpinachAPI } from '../../../../apis';
import { useExperienceTracking } from '../../../../hooks';
import SecondaryButton from '../../../stand-up/SecondaryButton';

interface MeetingTypeProps {
    summaryByType: Partial<Record<ScribeMeetingType, SummaryJson>> | null;
    setSummaryJson: (json: SummaryJson | null) => void;
    summaryJson: SummaryJson | null;
    botId: string;
    setToastText: (text: string) => void;
}

export function MeetingType({ summaryByType, setSummaryJson, summaryJson, botId, setToastText }: MeetingTypeProps) {
    const track = useExperienceTracking();
    const [selectedMeetingType, setSelectedMeetingType] = useState<ScribeMeetingType | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Box style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
            <Autocomplete<ScribeMeetingType, false, true, false>
                id="meeting-type-selection"
                options={Object.values(ScribeMeetingType)}
                value={
                    summaryByType
                        ? (Object.keys(summaryByType).find(
                              (key) => summaryByType[key as ScribeMeetingType] === summaryJson
                          ) as ScribeMeetingType) || undefined
                        : undefined
                }
                onChange={async (event: React.ChangeEvent<{}>, meetingType: ScribeMeetingType | null) => {
                    setSelectedMeetingType(meetingType);
                    if (!meetingType) return;

                    if (summaryByType?.[meetingType]) {
                        if (window.confirm(`Switch to ${meetingType} meeting type summary?`)) {
                            setSummaryJson(summaryByType[meetingType] ?? null);
                        }
                    }
                }}
                renderOption={(option) => (
                    <div>
                        {summaryByType?.[option] ? '(V) ' : '(X) '}
                        {option}
                    </div>
                )}
                renderInput={(params: any) => (
                    <TextField {...params} variant="outlined" placeholder="Meeting Type" size="small" />
                )}
                size="small"
                style={{ width: 200 }}
                disableClearable
                blurOnSelect={false}
                openOnFocus
            />
            {selectedMeetingType && !summaryByType?.[selectedMeetingType] && (
                <SecondaryButton
                    title={isLoading ? 'Generating...' : 'Generate'}
                    buttonStyles={{ marginLeft: '10px' }}
                    isLoading={isLoading}
                    onClick={async () => {
                        if (!selectedMeetingType) {
                            setToastText('Please select a meeting type first');
                            return;
                        }

                        if (window.confirm(`Generate summary for ${selectedMeetingType} meeting type?`)) {
                            setIsLoading(true);
                            try {
                                const response = await postSpinachAPI(SpinachAPIPath.Regenerate, {
                                    botId,
                                    meetingType: selectedMeetingType,
                                });

                                if (response) {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Regenerate Summary',
                                        MeetingType: selectedMeetingType,
                                    });
                                    setToastText('Summary generation started - please check back later!');
                                }
                            } catch (error) {
                                setToastText(error instanceof Error ? error.message : 'Failed to generate summary');
                            } finally {
                                setIsLoading(false);
                            }
                        }
                    }}
                />
            )}
        </Box>
    );
}
