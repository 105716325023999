import { Box } from '@material-ui/core';
import {
    CalendarTodayRounded,
    CallMade,
    DescriptionOutlined,
    DescriptionRounded,
    DraftsOutlined,
    HelpOutlineOutlined,
    PersonOutlineOutlined,
    PublishRounded,
    SchoolOutlined,
    SettingsOutlined,
    SettingsRemoteOutlined,
    SupervisorAccountOutlined,
    WidgetsOutlined,
} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';
import { getWebAppHost, isProductionStage } from '@spinach-shared/utils';

import { postLogOut } from '../../../apis/postLogOut';
import { ReactComponent as SpinachLogo } from '../../../assets/logo-spinachio.svg';
import { GlobalModal } from '../../../atoms';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalRouting,
    useGlobalSearchShortcut,
    useSidebar,
    useStripeUpgradeInAppEnablement,
} from '../../../hooks';
import { useGlobalDrafts } from '../../../hooks/useGlobalDrafts';
import { BodyRegular, lightTheme } from '../../../styles';
import { URLUtil, isWebPlatform } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { LoginWithGoogle } from '../../auth';
import { LoginWithMicrosoft } from '../../auth/LoginWithMicrosoft';
import { Anchor, ClientPath, Column, Hairline, Row, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { DashboardSelectorIcons } from './DashboardSelectorIcons';
import { ProfileComponent } from './NavBar';
import { ShowcaseGuide } from './ShowcaseGuide';
import { DashboardComponent } from './types';
import { isSummariesAndTranscriptsSection } from './utils';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

function SearchBox(): JSX.Element {
    const { setIsSearchVisible, isSearchVisible } = useGlobalAiDashboard();
    const { isMac } = useGlobalSearchShortcut('');
    const track = useExperienceTracking();

    return (
        <Box
            style={{
                padding: 5,
                cursor: 'pointer',
                marginBottom: '20px',
                border: '1px solid rgba(160, 160, 160, 0.3)',
                minWidth: '60%',
                width: 'calc(100% - 10px)',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: 'white',
                transition: 'background-color 0.2s ease',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
            }}
            onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'white';
            }}
            onClick={() => {
                setIsSearchVisible(!isSearchVisible);
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Search Meeting Notes in Sidebar',
                });
            }}
        >
            <SearchIcon style={{ color: '#A0A0A0' }} />
            <span style={{ color: 'gray', marginLeft: 5 }}>
                {`Search notes `}
                {isMac ? `(⌘ + K)` : `(Ctrl + K)`}
            </span>
        </Box>
    );
}

interface NavBarButtonProps {
    title: Omit<ReactNode, 'number' | 'boolean' | 'null' | 'undefined'>;
    section?: DashboardSection;
    activeSection?: DashboardSection;
    onClick: () => void;
    endIcon?: JSX.Element;
    startIcon: JSX.Element;
}

function NavBarButton({ title, section, activeSection, onClick, endIcon, startIcon }: NavBarButtonProps): JSX.Element {
    return (
        <Row>
            <Row
                style={{
                    marginTop: '1px',
                    marginBottom: '1px',
                    borderRadius: '8px',
                    padding: '5px 8px',
                    width: 'calc(100% - 7px)',
                    backgroundColor: section && activeSection === section ? 'rgba(0, 0, 0, 0.09)' : 'transparent',
                    transition: 'background-color 0.2s ease',
                    cursor: 'pointer',
                }}
                onMouseOver={(e) => {
                    if (!(section && activeSection === section)) {
                        e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
                    }
                }}
                onMouseOut={(e) => {
                    if (!(section && activeSection === section)) {
                        e.currentTarget.style.backgroundColor = 'transparent';
                    }
                }}
                onClick={onClick}
            >
                {startIcon}
                <SecondaryButton
                    title={title}
                    buttonStyles={{ textDecoration: 'none', display: 'flex' }}
                    labelStyles={{
                        fontSize: '16px',
                        fontWeight: 600,
                        display: 'flex',
                        width: '100%',
                        textAlign: 'left',
                    }}
                    color={lightTheme.primary.midnight}
                    onClick={onClick}
                    endIcon={endIcon}
                />
            </Row>
        </Row>
    );
}

export function Sidebar({
    activeSection,
    setActiveSection,
    setIsAddingAdditionalMeetings,
    shouldShowShowcaseGuide,
}: DashboardComponent & {
    setIsAddingAdditionalMeetings: (val: boolean) => void;
    shouldShowShowcaseGuide: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [drafts] = useGlobalDrafts();
    const track = useExperienceTracking();
    const [, setGlobalModal] = useGlobalModal();
    const isStripeUpgradeInAppEnabled = useStripeUpgradeInAppEnablement();
    const { routeToControl } = useGlobalRouting();
    const { fullSidebarWidth, userShouldSeeBothApps, appBarWidth, sidebarWidth, isDesktopView } = useSidebar();

    return (
        <Row
            style={{
                width: isDesktopView ? fullSidebarWidth : '100%',
                height: '100%',
                marginRight: '20px',
            }}
        >
            {userShouldSeeBothApps && isDesktopView ? (
                <Column
                    style={{
                        height: '100%',
                        width: `${appBarWidth}px`,
                        backgroundColor: `#E2E8E8`,
                        zIndex: 1,
                        paddingRight: '5px',
                        paddingLeft: '5px',
                    }}
                >
                    <DashboardSelectorIcons />
                </Column>
            ) : null}
            <Column
                style={{
                    height: '100%',
                    width: isDesktopView ? `${sidebarWidth}px` : '100%',
                    backgroundColor: `${lightTheme.neutrals.grayLight}`,
                    zIndex: 1,
                    position: 'relative',
                }}
            >
                <SidebarColumn>
                    {isDesktopView ? (
                        <SpinachLogo
                            style={{
                                height: isDesktopView ? '35px' : '25px',
                                minHeight: isDesktopView ? '35px' : '25px',
                                cursor: 'pointer',
                                marginLeft: '-35px',
                                marginBottom: '20px',
                                marginTop: '5px',
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Sidebar Logo' });
                                setActiveSection(DashboardSection.Summaries);
                            }}
                        />
                    ) : null}

                    {user.isAnonymous ? (
                        <>
                            <Spacing factor={1 / 5} />
                            <BodyRegular
                                style={{ fontSize: '18px', fontWeight: 700, color: lightTheme.primary.midnight }}
                            >
                                Try Spinach Free
                            </BodyRegular>
                            <Spacing factor={1 / 5} />

                            <LoginWithGoogle
                                variant="orange"
                                label="Continue with Google"
                                buttonStyle={{
                                    maxWidth: '225px',
                                    minWidth: '200px',
                                    width: '70vw',
                                    justifyContent: 'center',
                                    height: '40px',
                                    padding: '5px',
                                }}
                            />
                            <Spacing factor={1 / 3} />
                            <LoginWithMicrosoft
                                variant="orange"
                                label="Continue with Microsoft"
                                buttonStyle={{
                                    maxWidth: '225px',
                                    minWidth: '200px',
                                    width: '70vw',
                                    justifyContent: 'center',
                                    height: '40px',
                                    padding: '5px',
                                }}
                            />

                            <Spacing factor={1 / 2} />

                            <BodyRegular style={{ fontSize: '14px', color: lightTheme.primary.midnight }}>
                                Have an account?{' '}
                                <Anchor
                                    style={{ textDecoration: 'none' }}
                                    onClick={async () => {
                                        const response = await postLogOut();
                                        if (response.success) {
                                            window.location.href = getWebAppHost();
                                        }
                                    }}
                                >
                                    Sign in
                                </Anchor>
                            </BodyRegular>

                            <Hairline style={{ width: '70%' }} />
                        </>
                    ) : (
                        <>
                            {isDesktopView ? (
                                <>
                                    <ProfileComponent />
                                    <Spacing factor={1 / 2} />
                                </>
                            ) : null}

                            {isDesktopView ? <SearchBox /> : null}
                        </>
                    )}

                    <NavBarButton
                        title="Meeting History"
                        startIcon={
                            <DescriptionOutlined
                                style={{ marginRight: '10px' }}
                                htmlColor={lightTheme.secondary.midnight}
                            />
                        }
                        section={DashboardSection.Summaries}
                        activeSection={
                            isSummariesAndTranscriptsSection(activeSection) ? DashboardSection.Summaries : undefined
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries & Transcripts Tab' });
                            setActiveSection(DashboardSection.Summaries);
                        }}
                    />
                    <NavBarButton
                        title="Future Meetings"
                        startIcon={
                            <CalendarTodayRounded
                                style={{ marginRight: '10px' }}
                                htmlColor={lightTheme.secondary.midnight}
                            />
                        }
                        section={DashboardSection.Meetings}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Meetings Tab' });
                            if (user.isAnonymous) {
                                setGlobalModal(GlobalModal.CreateAccount);
                            } else {
                                setActiveSection(DashboardSection.Meetings);
                                setIsAddingAdditionalMeetings(false);
                            }
                        }}
                    />

                    {user.isAskSpinachPageEnabled && !user.isAnonymous ? (
                        <>
                            <NavBarButton
                                title="Ask Spinach"
                                startIcon={
                                    <span
                                        style={{
                                            fontSize: '14px',
                                            filter: 'brightness(20%) grayscale(100%)',
                                            marginRight: '12px',
                                            marginLeft: '3px',
                                        }}
                                    >
                                        ✨
                                    </span>
                                }
                                section={DashboardSection.AskSpinachPage}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Ask Spinach Page' });
                                    setActiveSection(DashboardSection.AskSpinachPage);
                                }}
                            />
                        </>
                    ) : null}
                    {user.isEnabledForDraftsSection && !user.isAnonymous ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <DraftsOutlined
                                        style={{ marginRight: '10px' }}
                                        htmlColor={lightTheme.secondary.midnight}
                                    />
                                }
                                title={
                                    <Row vCenter>
                                        Drafts{' '}
                                        {!!drafts?.length ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: '6px',
                                                    color: 'white',
                                                    width: '24px',
                                                    height: '24px',
                                                    borderRadius: '50%',
                                                    fontSize:
                                                        drafts.length < 10
                                                            ? '16px'
                                                            : drafts.length < 100
                                                            ? '14px'
                                                            : '10px',
                                                    backgroundColor: lightTheme.primary.greenLight,
                                                }}
                                            >
                                                {drafts.length > 99 ? '99+' : drafts.length}
                                            </div>
                                        ) : null}
                                    </Row>
                                }
                                section={DashboardSection.Drafts}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Drafts Tab',
                                        NumberOfDrafts: drafts?.length,
                                    });
                                    setActiveSection(DashboardSection.Drafts);
                                }}
                            />
                        </>
                    ) : null}
                    {user.isEnabledForFileUpload ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <PublishRounded
                                        style={{ marginRight: '10px' }}
                                        htmlColor={lightTheme.secondary.midnight}
                                    />
                                }
                                title="Upload"
                                section={DashboardSection.Upload}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Upload Tab' });
                                    if (user.isAnonymous) {
                                        setGlobalModal(GlobalModal.CreateAccount);
                                    } else {
                                        setActiveSection(DashboardSection.Upload);
                                    }
                                }}
                            />
                        </>
                    ) : null}
                    {user.isEnabledForControlInNavBar ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <SettingsRemoteOutlined
                                        style={{ marginRight: '10px' }}
                                        htmlColor={lightTheme.secondary.midnight}
                                    />
                                }
                                title="Agenda Controls"
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Open Agenda Controls from Navbar',
                                    });
                                    if (isWebPlatform()) {
                                        URLUtil.openURLPopUp(`${getWebAppHost()}${ClientPath.Control}`);
                                    } else {
                                        routeToControl();
                                    }
                                }}
                                endIcon={
                                    isWebPlatform() ? (
                                        <CallMade
                                            style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                                            htmlColor={lightTheme.primary.midnight}
                                        />
                                    ) : undefined
                                }
                            />
                        </>
                    ) : null}

                    <Hairline style={{ width: '70%' }} factor={1 / 2} />

                    {!user.isAnonymous ? (
                        <NavBarButton
                            startIcon={
                                <SettingsOutlined
                                    style={{ marginRight: '10px' }}
                                    htmlColor={lightTheme.secondary.midnight}
                                />
                            }
                            title="Settings"
                            section={DashboardSection.Integrations}
                            activeSection={activeSection}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                                setActiveSection(DashboardSection.Integrations);
                            }}
                        />
                    ) : null}

                    {user.isEnabledForEmbeddedWebflow ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <SchoolOutlined
                                        style={{ marginRight: '10px' }}
                                        htmlColor={lightTheme.secondary.midnight}
                                    />
                                }
                                title={user.embeddedWebflowCopy}
                                section={DashboardSection.Guides}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Embedded Webflow Tab' });
                                    setActiveSection(DashboardSection.Guides);
                                }}
                            />
                        </>
                    ) : null}

                    {isStripeUpgradeInAppEnabled && !user.isAnonymous ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <PersonOutlineOutlined
                                        style={{ marginRight: '10px' }}
                                        htmlColor={lightTheme.secondary.midnight}
                                    />
                                }
                                title="Account"
                                section={DashboardSection.Account}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Account Tab' });
                                    setActiveSection(DashboardSection.Account);
                                }}
                            />
                        </>
                    ) : null}

                    {user.hasAdminSectionAccess ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <SupervisorAccountOutlined
                                        style={{ marginRight: '10px' }}
                                        htmlColor={lightTheme.secondary.midnight}
                                    />
                                }
                                title="Admin"
                                section={DashboardSection.Admin}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Admin Tab' });
                                    setActiveSection(DashboardSection.Admin);
                                }}
                            />
                        </>
                    ) : null}

                    <NavBarButton
                        startIcon={
                            <HelpOutlineOutlined
                                style={{ marginRight: '10px' }}
                                htmlColor={lightTheme.secondary.midnight}
                            />
                        }
                        title="Help Center"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                            URLUtil.openURL(getClientConfigValue('INTERCOM_SUPPORT_URL'));
                        }}
                        endIcon={
                            <CallMade
                                style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                                htmlColor={lightTheme.primary.midnight}
                            />
                        }
                    />
                    {isProductionStage() || user.isAnonymous ? null : (
                        <>
                            <NavBarButton
                                startIcon={
                                    <WidgetsOutlined
                                        style={{ marginRight: '10px' }}
                                        htmlColor={lightTheme.secondary.midnight}
                                    />
                                }
                                title="Backoffice"
                                section={DashboardSection.Backoffice}
                                activeSection={activeSection}
                                onClick={() => {
                                    setActiveSection(DashboardSection.Backoffice);
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Backoffice Tab' });
                                }}
                            />
                        </>
                    )}
                </SidebarColumn>
                {shouldShowShowcaseGuide ? (
                    <div style={{ padding: '12px' }}>
                        <ShowcaseGuide />
                    </div>
                ) : null}
            </Column>
        </Row>
    );
}
